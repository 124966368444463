import Head from "next/head"
import { Button, TopRoundedWhiteCard } from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import withPrivateRoute from "../withPrivateRoute"
import { useRouter } from "next/router"

const Container = styled.section`
  padding: 1em 2em;
  .wrapper {
    padding: 10px;
    button {
      margin-right: 10px;
    }
  }
`

const Home = () => {
  const { t } = useTranslation()
  const { push } = useRouter()
  return (
    <>
      <Head>
        <title></title>
      </Head>
      <Container>
        <TopRoundedWhiteCard title={"easyy運営者管理画面"}>
          <div className={"wrapper"}>
            <Button onClick={() => push("/notification")}>
              {t("Notification management")}
            </Button>
            <Button onClick={() => push("/company")}>
              {t("Company information")}
            </Button>
          </div>
        </TopRoundedWhiteCard>
      </Container>
    </>
  )
}

export default withPrivateRoute(Home, { title: "easyy運営者管理画面" })
